.execDashboard-summary {
    padding: 10px 9%;
    display: flex;
    &__item {
        flex: 1;
        margin: 0 8px;
        &.avgSpan {
            .summary-info-card__label {
                flex: 3;
            }
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &__item-additional-info {
        > div {
            border-bottom: 1px solid #eeeeee;
            padding: 15px;
        }

        > div:last-child {
            border-bottom: none;
        }

        .percentage-info {
            color: #0c3c60;
        }
    }

    .avg-span__icon {
        position: relative;
        margin-left: 7px;
        vertical-align: text-top;
        cursor: pointer;
        top: 1px;
    }
}

.page-not-found {
    font-family: 'Lato', sans-serif;
    position: relative;
    text-align: center;
    width: 100%;
    margin: 20px 0;
    padding-top: 20%;

    &__label {
        font-weight: bold;
        color: #22576b;
        font-size: 30px;
        text-align: center;
        margin: 5px 0;
    }
}

.execDashboard-reports {
    > div {
        margin-bottom: 40px;
    }
    padding: 10px 8%;

    .total-cost-bar {
        rect {
            x: 220;
        }
        .execDashboard-reports__costChartLabel {
            position: relative;
            top: -37px;
        }
    }
    .title__functions {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }
    &__mainFunctions,
    &__subFunctions,
    &__N1-manager-chart {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    &__left-view {
        //padding: 5px 20px;
        > div {
            display: flex;
        }
        .pieCharts {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &.right-align-contractors {
                top: -40px;
            }
            &.left-align-contractors {
                top: -26px;
                left: -55px;
            }
        }
        .costchart,
        .pieCharts__container {
            border: 1px solid #b5c5ca;
            margin: 0 13px;
            padding: 20px;
        }
        .pieCharts__container {
            padding: 32px 4px;
            .total-ftes {
                display: flex;
                margin: 0 18% 0 0;
                &.adjust-total {
                    margin-left: 10%;
                }
                > div {
                    flex: 1;
                    font-size: 16px;
                    text-align: center;
                }
                .label {
                    color: #aaaaaa;
                }
                .value {
                    color: black;
                    font-weight: bold;
                }
            }

            .piechart__managers,
            .piechart__ic {
                position: relative;
            }

            .manager-contractors {
                justify-content: center;
                align-items: center;
                display: flex;
                color: #ffffff;
                bottom: 32px;
                width: 50px;
                height: 50px;
                position: absolute;
                background-color: #083d52;
                border-radius: 50%;
                right: 48px;
                font-size: 10px;
                &.adjust-contractors {
                    width: 70px;
                    height: 70px;
                    bottom: 40px;
                }
            }

            .ic-contractors {
                font-size: 10px;
                justify-content: center;
                align-items: center;
                display: flex;
                color: #ffffff;
                bottom: 40px;
                width: 70px;
                height: 70px;
                position: absolute;
                background-color: #46651f;
                border-radius: 50%;
                right: 48px;
                &.adjust-contractors {
                    width: 50px;
                    height: 50px;
                    bottom: 34px;
                }
            }

            &.adjust-container {
                padding: 27px;
            }
        }
        .total-fte-pieChart {
            flex: 1;

            .legendGroups {
                display: inline-block;
                margin: 10px 5px;
                .legends {
                    margin: 10px 0;
                    display: block;
                    div {
                        width: 88%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .circle {
                        width: 10px;
                    }
                }
            }
        }
        .total-cost-progress {
            flex: 1;
        }
        .total {
            margin: 25px;
            font-size: 20px;
            > div:first-child {
                color: #aaaaaa;
            }
            > div {
                display: flex;
                align-content: center;
                justify-content: center;
            }
        }
    }

    &__subFunctions {
        position: relative;
    }
    .subFunctions-close {
        position: absolute;
        top: 50px;
        //right: 30px;
        cursor: pointer;
    }

    &__label {
        color: #093b5c;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
    }

    .pieChart-label {
        font-family: 'Lato', sans-serif;
        font-weight: normal;
        text-align: center;
        .value {
            white-space: normal;
            font-size: 10px;
        }
        .count {
            margin-top: 10px;
            font-size: 15px;
            font-weight: bold;
        }
        .percentage {
            position: relative;
            font-size: 13px;
            top: 20px;
        }
    }
    .pie-chart-arrow-label {
        border-bottom: 1px solid #9f9f9f;
        padding: 0 5px 5px 0;
        display: inline-block;
        position: relative;
        z-index: 100;
        top: 24px;
        width: 60px;
        text-align: right;
        .label {
            font-size: 10px;
            color: #a1a1a1;
            line-height: 10px;
        }
        .value {
            font-size: 16px;
            font-weight: bold;
        }

        .arrow-down {
            width: 7px;
            position: absolute;
            border-bottom: 1px solid #a1a1a1;
            bottom: -3px;
            right: -6px;
            transform: rotate(45deg);
        }
        &.ic {
            top: 35px;
        }
    }
    .pie-chart-arrow-label-contractor {
        border-bottom: 1px solid #9f9f9f;
        padding: 0 5px 5px 0;
        display: inline-block;
        position: absolute;
        z-index: 100;
        bottom: -9px;
        right: -66px;
        width: 60px;
        text-align: right;
        .label {
            font-size: 10px;
            color: #a1a1a1;
            line-height: 10px;
        }
        .value {
            font-size: 16px;
            font-weight: bold;
            color: black;
        }

        .arrow-down {
            width: 16px;
            position: absolute;
            border-bottom: 1px solid #a1a1a1;
            bottom: 4px;
            left: -13px;
            transform: rotate(45deg);
        }
        &.ic {
            //top:35px;
        }
    }

    &__costChartLabel {
        font-family: 'Lato', sans-serif;
        font-size: 12px;
    }

    //&__locations {
    //    margin-bottom: 50px;
    //}

    &__locations-map {
        padding: 10px 20px;
    }

    &__locations-tooltip {
        fill: #5d5a6d;
        font-family: 'Lato', sans-serif;
    }

    &__bar-tooltip {
        background-color: white;
        padding: 10px 15px;
        border: 1px solid black;
        font-family: 'Lato', sans-serif;
    }

    &__functions {
        position: relative;
    }
    &__functions-chart,
    &__N1-manager-chart {
        flex: 8;
    }
    .subFunction__label {
        position: relative;
        top: -20px;
    }
    .subFunction__fte-label {
        transform: rotate(-270deg);
        display: inline-block;
        top: 15px;
        position: relative;
        left: 10px;
    }

    .viewSubFunctions {
        position: relative;
        cursor: pointer;
        text-align: center;
        min-width: 37px;
        &__tooltip {
            position: absolute;
            left: 20px;
            box-shadow: 0 0 5px 5px #f7f7f7;
            padding: 10px;
            top: -20px;
            border-radius: 5px;
            border: 1px solid black;
        }
    }
    &__functions-values,
    &__N1-manager-values {
        position: relative;
        flex: 2;

        .label {
            background-color: #ced7db;
            width: 50px;
            color: #093b5c;
            font-weight: bold;
            height: 50px;
            justify-content: center;
            align-content: center;
            text-align: center;
            border-radius: 50%;
            span {
                top: 10px;
                position: relative;
            }
        }
    }

    .hide {
        display: none;
    }
}

.executive-function__label,
.executive-n1-manager__label {
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.data-label {
    font-family: 'Lato', sans-serif;
    &__text {
        color: black;
        font-weight: bold;
        height: 37px;
        text-align: center;
    }
    &__span {
        background-color: #ced7db;
        width: 40px;
        height: 40px;
        color: #093b5c;
        font-weight: bold;
        text-align: center;
        border-radius: 50%;
        line-height: 40px;
        margin: 10px auto;
    }
}

.custom-tooltip {
    .__react_component_tooltip {
        background-color: white !important;
        padding: 20px 30px !important;
        box-shadow: 20px 20px 50px grey;
    }
    .__react_component_tooltip span {
        color: black;
        font-size: 18px;
        font-weight: bold;
    }
    .__react_component_tooltip:after {
        border-top-color: white !important;
    }
}

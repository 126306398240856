// Colors
$siteTextColor: #000000;
$white: #ffffff;
$white100: #dfdfdf;
$white200: #dfdddd;
$white700: #d0d0d0;

$black100: #000000;
$black200: #f7f7f7;
$black300: #edeff1;
$black400: #d5d5d5;
$black500: #a3a3a3;
$black600: #4a4a4a;
$black700: #e6e6e6;
$black800: #959595;
$black900: #849dad;

$grey100: #f5f5f5;
$grey200: #e1e1e1;
$grey300: #b8b8b8;
$grey400: #eef5f6;
$grey500: #d8dede;
$grey600: #e5e5e5;
$grey700: #999999;
$grey800: #979797;
$grey900: #e9e9e9;

$darkBlue: #093b5e;
$darkBlue100: #134364;
$darkBlue200: #093b5e;
$darkBlue300: #2f3a4d;

$red100: #ff0000;
$red200: #fd9510;

$blue100: #00bfd8;

$green100: #96ce00;
$green200: #8cc63f;
$green300: #003d54;

.no-data-found {
    font-family: 'Lato', sans-serif;
    position: relative;
    text-align: center;
    width: 100%;
    margin: 20px 0;
    &__logo {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    &__label {
        font-weight: bold;
        color: #22576b;
        font-size: 20px;
        text-align: center;
        margin: 5px 0;
    }
}

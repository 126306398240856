@import 'variables';
@import 'responsive';

body,
html {
    padding: 0;
    height: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: $siteTextColor;
    font-family: 'Lato', sans-serif !important;
}

body {
    #root {
        width: 100%;
        min-height: 100%;

        .topHeaders {
            position: fixed;
            top: 0;
            z-index: 1000;
            box-shadow: 0 11px 10px -10px rgba(0, 0, 0, 0.5);
        }

        .contentWrapper,
        .topHeaders {
            width: 100%;
            margin: 0;
        }
        .sticky {
            position: fixed;
            top: 88px;
            z-index: 1000;
            width: 100%;
            background-color: rgb(255, 255, 255);
            transition: smooth;
        }
    }

    .ellipsisPro {
        overflow: hidden;
        word-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

.text-center {
    text-align: center;
}

.clearFix {
    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}
.contentWrapper.designProgress .showStructures .phaseLink.strLink {
    display: none;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0 2px;
    &.brick {
        width: 30px !important;
        height: 10px !important;
        border-radius: 0% !important;
    }
    .fill {
        background-color: rgb(71, 105, 130);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        &.ideal {
            background-color: #a2cf63;
        }
        &.grey1 {
            background-color: #71797e;
        }
        &.grey2 {
            background-color: #c0c0c0;
        }
        &.grey3 {
            background-color: #e5e4e2;
        }
        &.bluegrey {
            background-color: rgb(110, 135, 154);
        }
        &.skyblue {
            background-color: hsl(206, 51%, 82%);
        }
        &.pattern1 {
            background-image: url('../assets/pattern.png');
            background-size: contain;
        }
    }
}

@media (max-width: $largeContentWidth) {
    body {
        #root {
            .contentWrapper,
            .topHeaders {
                width: 100%;
                left: 0;
            }
        }
    }
}
